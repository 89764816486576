const input = document.querySelector('input');
const button = document.querySelector('button');

input.addEventListener('input', (event) => {
  if (event.target.value.length) {
    input.classList.add('dirty');
    button.removeAttribute('disabled');
  } else {
    input.classList.remove('dirty');
    button.setAttribute('disabled', true);
  }
})


// document.querySelectorAll('input').forEach((input) => {
//   input.addEventListener('input', (e) => input.classList.toggle('dirty', e.target.value.length));
//   const form = input.closest('form');
//   form && form.addEventListener('reset', (event) => {
//     input.classList.remove('dirty');
//   });
// });


// forms.forEach((form) => {
//   const targets = form.querySelectorAll('input,select,textarea,label');
//   const actions = form.querySelectorAll('[type="reset"], [type="submit"]');
//   targets.forEach((target) => {
//     target.addEventListener('input', (event) => {
//       if (e.target.value.length) {

//       } else {

//       }
//       actions.forEach((action) => action.removeAttribute('disabled'));
//     })
//   });
//   form.addEventListener('reset', () => {
//     targets.forEach((target) => target.classList.remove('dirty'));
//       actions.forEach((action) => action.setAttribute('disabled', true));
//   })
// })
